import {WebSocketMessage} from "@/app/shared/model/WebSocketMessage";
import {
  VideoCallMessageDialogComponent
} from "@/app/shared/modules/Video-call-message-dialog/Video-call-message-dialog.component";
import {VideoDialogComponent} from "@/app/shared/modules/video-dialog/video-dialog.component";
import {ActivityLogsService} from "@/app/shared/services/activityLogs.service";
import {GlobalErrorHandingService} from "@/app/shared/ErrorHandler/GlobalErrorHanding.service";
import {environment} from "@/environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar, MatSnackBarRef} from "@angular/material/snack-bar";
import {Stomp} from "@stomp/stompjs";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import SockJS from 'sockjs-client';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};


@Injectable()
export class WebSocketService {
  public snackBarRef: MatSnackBarRef<any>;

  url: string = environment.API_URL + '/api/socket';
  ws: any;
  // today's date
  sinceDate: any = new Date();
  webSocketMessages: WebSocketMessage[] = [];
  loggedInWebSocketMessages: WebSocketMessage;
  isCustomSocketOpened = false;
  isLoaded = false;
  private webSocketEndPoint = environment.API_URL + '/socket';
  private stompClient;

  constructor(private http: HttpClient,
              public snackbar: MatSnackBar,
              public dialog: MatDialog,
              private errorService: GlobalErrorHandingService,
              private ACTIVITYLOGSSERVICE: ActivityLogsService
  ) {
  }

  postWebSocketRequestUsingRest(data: WebSocketMessage): Observable<WebSocketMessage> {
    return this.http.post<WebSocketMessage>(this.url, data, httpOptions)
      .pipe(
        catchError(this.errorService.handleError('postWebSocketRequestUsingRest',
          new WebSocketMessage(null, null, null, null, null, null, null))) // then handle the error
      );
  }

  disconnect() {
    if (this.ws != null) {
      this.ws.close();
    }
    if (this.stompClient != null) {
      this.stompClient.disconnect();
    }
    this.setConnected(false);
    console.log('Disconnected');
  }

  setConnected(connected) {
    this.isLoaded = connected;
    this.webSocketMessages = [];
  }

  initializeWebSocketConnection() {
    console.log('Initialize WebSocket Connection');
    //this.ws = new SockJS(this.webSocketEndPoint);
    const socket = new SockJS(this.webSocketEndPoint);
    console.log(this.ws);
    this.stompClient = Stomp.over(socket);
    const that = this;
    this.stompClient.connect({}, function () {
      that.isLoaded = true;
      // Subscribe to the topic
      that.userloggedInAdmin();
      // that.openGlobalSocket();
    }); // , that.errorCallBack);
  }

  connect() {
    const socket = new SockJS(this.webSocketEndPoint); // Adjust URL as needed
    this.stompClient = Stomp.over(socket);

    this.stompClient.connect({}, frame => {
      console.log('Connected: ' + frame);

      // Subscribe to the topic
      this.stompClient.subscribe('/topic/messages', message => {
        console.log('Received message: ', message.body);
      });
    });
  }


  sendMessage(message: string) {
    this.stompClient.send('/app/send', {}, message); // Sending a message
  }
  userloggedInAdmin() {
    this.stompClient.subscribe('/topic/99999', (webSocketMessage) => {
      console.log('Socket is Opened for userloggedInAdmin ' + JSON.stringify(webSocketMessage));
      this.handleAdminResult(webSocketMessage);
    });
  }

  sendWSMsgToAdmin(argWebSocketMessage: WebSocketMessage) {
    if (this.isLoaded && argWebSocketMessage && this.stompClient) {
      this.stompClient.send('/app/userLoggedInToAdmin', {}, JSON.stringify(argWebSocketMessage));
    }
  }

  // on error, schedule a reconnection attempt
  errorCallBack(error) {
    console.log('errorCallBack -> ' + error);
    setTimeout(() => {
      this.initializeWebSocketConnection();
    }, 1000);
  }


  // #################### PATIENT SOCKET- START ####################
  sendWSMsgToOtherUser(argWebSocketMessage: WebSocketMessage) {
    this.stompClient.debug = (msg) => {
      console.log('STOMP debug:', msg);
    };
    if (argWebSocketMessage && this.stompClient) {
      console.log("Sending message:", argWebSocketMessage);
      this.stompClient.send('/app/sendToOtherUserMsg', {}, JSON.stringify(argWebSocketMessage));
    }
  }

  openPatSocket(argSubscriberId) {
    if (this.isLoaded) {
      this.isCustomSocketOpened = true;
      this.stompClient.subscribe('/topic/' + argSubscriberId, (webSocketMessage) => {
        this.handlePatResult(webSocketMessage);
        console.log('Socket is Opened for ' + argSubscriberId);
      });
    }
  }

  handlePatResult(webSocketMessage) {
    console.log('handlePatResult');
    console.log(webSocketMessage.body);
    if (webSocketMessage.body) {
      const webSocketMessageResult: WebSocketMessage = JSON.parse(webSocketMessage.body);
      console.log(JSON.stringify(webSocketMessageResult));
      this.webSocketMessages.push(webSocketMessageResult);
      const index = this.webSocketMessages.length;
      if (this.webSocketMessages[index - 1].action === 'CALL') {
        this.openPatCallAcceptDialogue(this.webSocketMessages[index - 1]);
      }
      if ((this.webSocketMessages[index - 1].action === 'REJECTED') && (this.webSocketMessages[index - 1].fromTo === 'DOC-PAT')) {
        const msg = 'Doc Closed the Call !';
        this.closeDialogueBoxAndStream(msg);
      }
    }
  }


  openPatCallAcceptDialogue(argMessage: WebSocketMessage): void {
    console.log('openPatCallAcceptDialogue');
    const dialogRef = this.dialog.open(VideoCallMessageDialogComponent, {
      data: {title: 'DocHub is Calling You!'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        const webSocketMessage = new WebSocketMessage(argMessage.toId, argMessage.fromId,
          argMessage.description,
          'REJECTED', null, 'PAT-DOC', null);
        console.log(webSocketMessage);
        this.sendWSMsgToOtherUser(webSocketMessage);
        return;
      }
      this.openPatCallAcceptVideoDialog(argMessage);
    });
  }

  openPatCallAcceptVideoDialog(argMessage: WebSocketMessage): void {
    const dialogRef = this.dialog.open(VideoDialogComponent, {
      hasBackdrop: false,
      disableClose: false,
      data: {
        callType: argMessage.callType,
        patId: argMessage.toId,
        docId: argMessage.fromId,
        fromTo: 'PAT-DOC',
        channelName: argMessage.channelName
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        // TODO if patient disconnect the call we should close the stream
        // send webSocketMessage to Doc that call has been rejected
        const webSocketMessage = new WebSocketMessage(argMessage.toId, argMessage.fromId,
          argMessage.description, 'REJECTED', argMessage.callType, 'PAT-DOC', null);
          this.sendWSMsgToOtherUser(webSocketMessage);
        return;
      }
    });
  }

  // #################### PATIENT SOCKET- END ####################

  // #################### DOC SOCKET- START ####################
  openDocSocket(argSubscriberId) {
    if (this.isLoaded) {
      this.isCustomSocketOpened = true;
      this.stompClient.subscribe('/topic/' + argSubscriberId, (webSocketMessage) => {
        this.handleDocResult(webSocketMessage);
        console.log('DOC Socket is Opened for ' + argSubscriberId);
      });
    }
  }

  handleDocResult(webSocketMessage) {
    console.log('handleDocResult');
    console.log(webSocketMessage.body);
    if (webSocketMessage.body) {
      const webSocketMessageResult: WebSocketMessage = JSON.parse(webSocketMessage.body);
      console.log(webSocketMessageResult);
      this.webSocketMessages.push(webSocketMessageResult);
      // close the doc video calling stream
      const index = this.webSocketMessages.length;
      if ((this.webSocketMessages[index - 1].action === 'REJECTED') && (this.webSocketMessages[index - 1].fromTo === 'PAT-DOC')) {
        const msg = 'Patient Closed the Call !';
        this.closeDialogueBoxAndStream(msg);
      }
    }
  }

  closeDialogueBoxAndStream(argMessage: string) {
    console.log('insdide REJECTED');
    // since call are close now close all the streams
   // this.videoCallService.closeCallStreams();
    // TODO check how we can close the specific dialog
    this.dialog.closeAll();
    this.snackBarRef = this.snackbar.open(argMessage,
      'close',
      {
        duration: 5000,
        verticalPosition: 'bottom', // 'top' | 'bottom'
        horizontalPosition: 'end', // 'start' | 'center' | 'end' | 'left' | 'right'
        panelClass: 'red-snackbar-call'
      }
    );
    //  this.videoCallService.onNoClick();
    this.snackBarRef.onAction().subscribe(() => {
      console.log('The snack-bar action was triggered!');
    });
  }

  // #################### DOCTOR SOCKET- END ####################
  // #################### ADMIN SOCKET- START ####################
  openAdminSocket(argSubscriberId: number) {
    if (this.isLoaded) {
      this.isCustomSocketOpened = true;
      this.stompClient.subscribe('/topic/' + argSubscriberId, (webSocketMessage: any) => {
        this.handleAdminResult(webSocketMessage);
        console.log('Admin Socket is Opened for ' + argSubscriberId);
      });
    }
  }

  handleAdminResult(webSocketMessage : any) {
    console.log('handleAdminResult');
    console.log(webSocketMessage.body);
    if (webSocketMessage.body) {
      // reset the messages
      const webSocketMessageResult: WebSocketMessage = JSON.parse(webSocketMessage.body);
      console.log(webSocketMessageResult);
      this.loggedInWebSocketMessages = webSocketMessageResult;
      let msg = ' ';
        if (webSocketMessageResult.action === 'LOGGEDIN') {
          msg = msg + webSocketMessageResult.description + ' Logged In @' + webSocketMessageResult.sendDateTime + '  ';
        }
        if (webSocketMessageResult.action === 'LOGGEDOUT' ||  webSocketMessageResult.action === 'DISCONNECTED') {
          msg = msg + webSocketMessageResult.description + ' Logged Out @' + webSocketMessageResult.sendDateTime + '  ';
        }
        this.ACTIVITYLOGSSERVICE.LOG(msg, webSocketMessageResult.fromId);
       this.snackBarRef = this.snackbar.open(msg,
         'close',
         {
           duration: 90000,
           verticalPosition: 'bottom', // 'top' | 'bottom'
           horizontalPosition: 'end', // 'start' | 'center' | 'end' | 'left' | 'right'
           panelClass: 'red-snackbar-call'
         }
       );
       this.snackBarRef.onAction().subscribe(() => {
         console.log('The snack-bar action was triggered!');
       });
    }
  }
  checkIfUserIsLoggedIn(argUserId: number) {
    console.log(this.loggedInWebSocketMessages);
    // now check if the user is logged in
    if (undefined !== this.loggedInWebSocketMessages) {
      for (const adminMsg of this.loggedInWebSocketMessages.loggedInPersonsList) {
        if (argUserId === Number(adminMsg)) {
          return true;
        }
      }
    }

    return false;
  }
}
