import {MedicationDetails} from './medicationDetails';

export class WebSocketMessage {
  public description: string;
  public fromId: number;
  public toId: number;
  public sendDateTime: string;
  public action: string;
  public callType: number;
  public fromTo: string;
  public channelName: string;
  public loggedInPersonsList: string[] = new Array<string>();

  constructor(
              private argFromId: number,
              private argToId: number,
              private argDescription: string,
              private argAction: string,
              private argCallType: number,
              private argFromTo: string,
              private argChannelName: string) {
    this.description = argDescription;
    this.fromId = argFromId;
    this.toId = argToId;
    this.action = argAction;
    this.sendDateTime = new Date().toLocaleString();
    this.callType = argCallType;
    this.fromTo = argFromTo;
    this.channelName = argChannelName;
  }
}
