/*
 *  ZAITEC CONFIDENTIAL
 *  __________________
 *
 * [2019] - [2020] zaiTEC Logixs Ltd
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property of  zaiTEC Logixs Ltd and its suppliers,if any.
 *  The intellectual and technical concepts contained herein are proprietary to zaiTEC Logixs Ltd, and are protected by
 *  trade secret or copyright law.Dissemination of this information or reproduction of this material is strictly forbidden
 *  unless prior written permission is obtained from  zaiTEC Logixs Ltd.
 */

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, retry} from 'rxjs/operators';
import {MetaData} from '../model/metadata';
import {environment} from '../../../environments/environment';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {GlobalErrorHandingService} from 'src/app/shared/ErrorHandler/GlobalErrorHanding.service';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

const GET_ALLMETADATAS_URI: string = environment.API_URL + '/metadata/allmetadatas';
const CREATE_METADATAS_URI: string = environment.API_URL + '/metadata/create';
const UPDATE_METADATAS_URI: string = environment.API_URL + '/metadata/update';


@Injectable()
export class MetaDataService {

  metadata = new MetaData;
  private MetaDataDetail = new BehaviorSubject<MetaData>(this.metadata);
  MetaDataDetailsSubjectCast = this.MetaDataDetail.asObservable();

  private formModeSubject = new BehaviorSubject<string>('CRT');
  formModeCast = this.formModeSubject.asObservable();

  constructor(private http: HttpClient, private errorService: GlobalErrorHandingService ) {
  }


  // Create MetaData
  createMetaData(metaData: MetaData): Observable<MetaData> {
    const url = CREATE_METADATAS_URI;
    return this.http.post<MetaData>(url, metaData, httpOptions)
      .pipe(
        retry(1), // retry a failed request up to 3 times
        catchError(this.errorService.handleError('Not Found Type Error', metaData)) // then handle the error
      );
  }

  updateMetadataDetails(metaDataDetailEdit: MetaData): Observable<MetaData> {
    const url = UPDATE_METADATAS_URI + `/${metaDataDetailEdit.metaDataId}`;
    return this.http.post<MetaData>(url, metaDataDetailEdit, httpOptions)
      .pipe(
        catchError(this.errorService.handleError('Not Found Type Error', metaDataDetailEdit)) // then handle the error
      );
  }

  setMetaData(MetaData: MetaData) {
    console.log('Received in Metadata Service :' + MetaData);
    this.MetaDataDetail.next(MetaData);
  }

  changeMetadataDetailsFormMode(mode) {
    this.formModeSubject.next(mode);
  }

  // Get All App Users List
  getAllMetaDataList() {
    return this.http.get<MetaData[]>(GET_ALLMETADATAS_URI)
      .pipe(
        catchError(this.errorService.handleError('Not Found Type Error', [])) // then handle the error
      );
  }


}
